import * as React from "react";
import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import {
  getResources,
  useTranslate,
  Translate,
  ReduxState,
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
} from "react-admin";
// @ts-ignore
import inflection from "inflection";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";
import SubMenu from "../subMenu/subMenu.component";

// Icons
import DefaultIcon from "@material-ui/icons/ViewList";
import UserIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import RoomIcon from "@material-ui/icons/Room";
import ExploreIcon from "@material-ui/icons/Explore";
import LabelIcon from "@material-ui/icons/Label";
import InfoIcon from "@material-ui/icons/Info";
import DateRangeIcon from "@material-ui/icons/DateRange";
import FilterListIcon from "@material-ui/icons/FilterList";
import StarIcon from "@material-ui/icons/Star";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import GavelIcon from "@material-ui/icons/Gavel";
import MapIcon from "@material-ui/icons/Map";
import TerrainIcon from "@material-ui/icons/Terrain";

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5em",
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
  { name: "RaMenu" }
);

const translatedResourceName = (resource: any, translate: Translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const Menu: FC<MenuProps> = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const { permissions } = usePermissions();

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  return (
    <List
      className={classnames(classes.main, className)}
      component="nav"
      {...rest}
    >
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {/*resources
                .filter(r => r.hasList)
                .map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(
                            resource,
                            translate
                        )}
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        dense={dense}
                        sidebarIsOpen={open}
                    />
                    ))*/}
      {permissions === "admin" && (
        <MenuItemLink
          to="/users"
          primaryText="Users"
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={"App Settings"}
          icon={<SettingsIcon />}
          links={[
            { text: "Settings", link: "/settings/1", icon: <SettingsIcon /> },
            {
              text: "Terms and conditions",
              link: "/terms",
              icon: <GavelIcon />,
            },
            {
              text: "Time periods",
              link: "/timeperiods",
              icon: <DateRangeIcon />,
            },
            {
              text: "App bottom menu",
              link: "/appmenu/1",
              icon: <DefaultIcon />,
            },
            {
              text: "Explore order",
              link: "/exploreOrder/1",
              icon: <DefaultIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <MenuItemLink
          to="/areas"
          primaryText="Areas"
          leftIcon={<AccountTreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {permissions === "admin" && (
        <SubMenu
          text={"Information"}
          icon={<InfoIcon />}
          links={[
            { text: "Information", link: "/information", icon: <InfoIcon /> },
            {
              text: "Tags",
              link: "/informationtags",
              icon: <LabelIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}

      {permissions === "admin" && (
        <SubMenu
          text={"Area of Interests"}
          icon={<MapIcon />}
          links={[
            { text: "Area of Interests", link: "/aois", icon: <RoomIcon /> },
            {
              text: "Categories",
              link: "/aoicategories",
              icon: <DefaultIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={"Point of Interests"}
          icon={<MapIcon />}
          links={[
            { text: "Point of Interests", link: "/pois", icon: <RoomIcon /> },
            {
              text: "Categories",
              link: "/poicategories",
              icon: <DefaultIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={"Paths"}
          icon={<ExploreIcon />}
          links={[
            { text: "Paths", link: "/paths", icon: <CallSplitIcon /> },
            {
              text: "Categories",
              link: "/pathcategories",
              icon: <DefaultIcon />,
            },
            // { text: 'Areas', link: '/pathsAreas', icon: <AccountTreeIcon /> },
            { text: "Tags", link: "/tags", icon: <LabelIcon /> },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text="Snowmobiles"
          icon={<ExploreIcon />}
          links={[
            {
              text: "Recommendations",
              link: "/snowmobile/trails",
              icon: <StarIcon />,
            },
            {
              text: "Sub Paths",
              link: "/snowmobile/subpaths",
              icon: <LinearScaleIcon />,
            },
            {
              text: "Free Riding Areas",
              link: "/snowmobile/fras",
              icon: <ControlCameraIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {/* NOTE: Grooming menu */}
      {(permissions === "admin" || permissions === "groomer") && (
        <SubMenu
          text={"Grooming"}
          icon={<TerrainIcon />}
          links={[
            ...(permissions === "admin"
              ? [
                  {
                    text: "Groups",
                    link: "/grooming/filter/paths",
                    icon: <FilterListIcon />,
                  },
                ]
              : []),
            {
              text: "Cross-country Paths",
              link: "/grooming/paths",
              icon: <CallSplitIcon />,
            },
            {
              text: "Snowmobile Paths",
              link: "/grooming/snowmobile/subpaths",
              icon: <LinearScaleIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {/* NOTE: Common menu */}
      {/*<MenuItemLink
                to="/help"
                primaryText="Help"
                leftIcon={<HelpIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />*/}
      {isXSmall && logout}
    </List>
  );
};

export interface MenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  logout?: ReactNode;
  onMenuClick?: () => void;
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
