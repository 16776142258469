import { useEffect, useState } from "react";
import { Button, useDataProvider, useGetList, useQuery } from "react-admin";
import { apiUrl, httpClient } from "../../dataProvider";
import ArrowDown from "@material-ui/icons/ArrowDownward";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import { on } from "stream";

type ExploreItem = {
  id: string;
  order: number;
  pathCategoryIdentifier?: string;
  poiCategoryIdentifier?: string;
  pathCategoryName?: string;
  poiCategoryName?: string;
};
export const ExploreOrder = () => {
  const [data, setData] = useState<ExploreItem[] | null>();
  const [status, setStatus] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await httpClient(apiUrl + "/exploreItems").then(
        ({ json }) => ({
          data: json,
        })
      );
      setData(data);
    };
    fetchData();
  }, []);

  const update = async () => {
    const result = await httpClient(apiUrl + "/exploreItems", {
      method: "POST",
      body: JSON.stringify(data),
    });
    if (result.status === 200) {
      setStatus("Sparat!");
    } else {
      setStatus("Något gick fel");
    }
  };

  if (!data) {
    return <></>;
  }

  const onArrowClick = (direction: "up" | "down", identifier: string) => {
    const index = data.findIndex(
      (category) =>
        category.pathCategoryIdentifier === identifier ||
        category.poiCategoryIdentifier === identifier
    );
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < data.length) {
      const newData = [...data];
      [newData[index].order, newData[newIndex].order] = [
        newData[newIndex].order,
        newData[index].order,
      ];
      newData.sort((a, b) => a.order - b.order);
      setData(newData);
    }
    setStatus(null);
  };

  return (
    <div style={{ margin: 24 }}>
      <h1>Här kan du ändra ordningen på det som visas i "Utforska" i appen</h1>
      <div>
        {data?.map((category, index) => {
          const identifier =
            category.pathCategoryIdentifier ?? category.poiCategoryIdentifier;
          const name = category.pathCategoryName || category.poiCategoryName;
          return (
            <div
              key={category.id}
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                gap: 24,
                justifyContent: "space-between",
              }}
            >
              <h3>{name}</h3>
              <div style={{ display: "flex", gap: 12 }}>
                <div
                  style={{ opacity: index === 0 ? 0.3 : 1 }}
                  onClick={() => onArrowClick("up", identifier!)}
                >
                  <ArrowUp />
                </div>

                <div
                  style={{ opacity: index === data?.length - 1 ? 0.3 : 1 }}
                  onClick={() => onArrowClick("down", identifier!)}
                >
                  <ArrowDown />
                </div>
              </div>
            </div>
          );
        })}
        <Button
          style={{ marginTop: 24, width: 100, height: 50, textAlign: "center" }}
          onClick={update}
          label="Spara"
          color="primary"
          variant="contained"
        />
        {status && <p>{status}</p>}
      </div>
    </div>
  );
};
