import { Button, useGetOne, useRecordContext, useUpdate } from "react-admin";
import { useState } from "react";

export enum NavBarOptions {
  EXPLORE = "EXPLORE",
  TRACK_STATUS_MAP = "TRACK_STATUS_MAP",
  TRAIL_STATUS_MAP = "TRAIL_STATUS_MAP",
  PATHS_MAP = "PATHS_MAP",
  PATHS_MAP_SKIING = "PATHS_MAP_SKIING",
  PATHS_MAP_SNOWMOBILING = "PATHS_MAP_SNOWMOBILING",
  PATHS_MAP_BIKING = "PATHS_MAP_BIKING",
  PATHS_MAP_HIKING = "PATHS_MAP_HIKING",
  PATHS_MAP_WINTERHIKING = "PATHS_MAP_WINTERHIKING",
  ACTIVITY_PASS = "ACTIVITY_PASS",
}

type NavbarOptionsType = {
  label: string;
  value: NavBarOptions;
};

const prettyTitle = (value: NavBarOptions) => {
  switch (value) {
    case NavBarOptions.EXPLORE:
      return "Utforska";
    case NavBarOptions.TRACK_STATUS_MAP:
      return "Pistningskarta";
    case NavBarOptions.TRAIL_STATUS_MAP:
      return "Pistningskarta skoter";
    case NavBarOptions.PATHS_MAP:
      return "Ledkarta";
    case NavBarOptions.PATHS_MAP_SKIING:
      return "Ledkarta längdskidor";
    case NavBarOptions.PATHS_MAP_SNOWMOBILING:
      return "Ledkarta skoter";
    case NavBarOptions.PATHS_MAP_HIKING:
      return "Ledkarta vandring";
    case NavBarOptions.PATHS_MAP_WINTERHIKING:
      return "Ledkarta vintervandring";
    case NavBarOptions.PATHS_MAP_BIKING:
      return "Ledkarta cykel";
    case NavBarOptions.ACTIVITY_PASS:
      return "Fjällpass";
  }
};
export const AppNavBar = (props: any) => {
  const [selectedOptions, setSelectedOptions] = useState<NavbarOptionsType[]>(
    []
  );
  const [message, setMessage] = useState("");
  const record = useRecordContext();

  const { loading } = useGetOne("settings", props.id, {
    onSuccess: (data) => {
      const _selectedOptions: NavbarOptionsType[] =
        data?.data?.navBarOptions.map((option) => {
          return {
            label: prettyTitle(option),
            value: option,
          };
        });
      setSelectedOptions(_selectedOptions);
    },
  });

  const [update] = useUpdate(
    "settings",
    props.id,
    {
      ...record,
      navBarOptions: selectedOptions.map((option) => option.value),
    },
    null,
    { onSuccess: () => setMessage("Success!") }
  );

  const updateOption = (value: NavBarOptions, index: number) => {
    const _selectedOptions = [...selectedOptions];
    _selectedOptions[index] = { ..._selectedOptions[index], value };
    setSelectedOptions(_selectedOptions);
  };

  if (loading) {
    return <></>;
  }
  return (
    <div>
      <h2>Här kan man ändra vilka menyval som visas i bottenmenyn på appen </h2>
      <div
        style={{
          display: "flex",
          marginTop: 36,
          marginBottom: 36,
          flexDirection: "row",
          gap: 12,
        }}
      >
        {selectedOptions?.map((option: NavbarOptionsType, index) => (
          <select
            value={option.value}
            style={{ padding: 16, borderRadius: 12 }}
            onChange={(e) =>
              updateOption(e.target.value as NavBarOptions, index)
            }
          >
            {Object.keys(NavBarOptions).map((option: string, index) => (
              <option
                value={option}
                onSelect={() => {
                  updateOption(option as NavBarOptions, index);
                }}
              >
                {prettyTitle(option as NavBarOptions)}
              </option>
            ))}
          </select>
        ))}
      </div>
      <Button
        color="primary"
        variant="outlined"
        label="Save"
        onClick={() => {
          update();
        }}
      />
      <h2>{message}</h2>
    </div>
  );
};
